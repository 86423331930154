body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #222222;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.App {
  color: #ffffff;
}

.white.App {
  color: #000000;
}

.container {
  max-width: 600px;
  margin: auto;
  padding-top: 64px;
  padding-bottom: 48px;
}

.logo {
  display: block;
  margin: auto;
}

label {
  font-size: 18px;
  font-weight: 600;
  margin: 16px 0;
  display: block;
}

#text {
  display: block;
  width: 100%;
  appearance: none;
  background-color: #343434;
  color: #fff;
  padding: 16px;
  resize: vertical;
  border-radius: 7px;
  border: none;
  font-size: 16px;
  min-height: 240px;
  outline: none!important;
}

.white #text {
  background-color: rgba(196,196,196,0.2);
  color: #000;
}

.switch {
  display: flex;
  width: 100%;
  background-color: #343434;
  border-radius: 7px;
  overflow: hidden;
}

.white .switch {
  background-color: rgba(196,196,196,0.2);
}

.switch-item {
  line-height: 36px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: background-color .2s ease;
  -webkit-tap-highlight-color: transparent;
}

.switch-item.selected {
  background-color: #0070F3;
}

.white .switch-item.selected {
  background-color: rgba(255,178,178,0.5);
}

.button {
  display: block;
  appearance: none;
  border: none;
  border-radius: 7px;
  height: 36px;
  max-width: 300px;
  margin: auto;
  margin-top: 36px;
  background-color: #0070F3;
  color: #fff;
  font-size: 16px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  outline: none!important;
  box-shadow: 0 8px 16px -4px rgba(0, 113, 243, 0.18);
  transition: background-color .2s ease, box-shadow .2s ease;
}

.white .button {
  background-color: rgba(255,178,178,0.5);
  color: #000;  
}

.button:focus {
  box-shadow: 0 8px 32px -4px rgba(0, 113, 243, 0.32);
}

.white .button:focus {
  box-shadow: 0 8px 32px -4px rgba(255,178,178,0.6);
}

.button:hover {
  background-color: #0278ff;
}

.white .button:hover {
  background-color: rgba(255,178,178,0.6);
}

.button:active {
  background-color: #0264d5;
}

.notify {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #0070F3;
  color: #fff;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: hidden;
  padding: 0 16px;
  overflow: hidden;
  transform: translateY(100%);
  opacity: 0;
  transition: transform .2s ease, opacity .2s ease;
  box-shadow: 0 -4px 16px 0 rgba(0,0,0,.08);
}

.white .notify {
  background-color: rgba(255,178,178,0.5);
  color: #000;
}

.notify.show {
  transform: none;
  opacity: 1;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggler {
  width: 50px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0f1114;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.toggler-image {
  width: 17px;
  height: 17px;
  transition: opacity .3s ease;
}

.toggler-button {
  width: 22px;
  height: 22px;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  top: 1px;
  left: 1px;
  transition: all .5s cubic-bezier(.23,1,.32,1) 0ms;
}

@media (max-width: 632px) {
  label {
    font-size: 16px;
  }

  .container {
    padding: 48px 16px 48px 16px;
  }
  
  .switch {
    flex-wrap: wrap;
  }

  .switch-item {
    width: 100%;
    line-height: 48px;
  }

  #text {
    min-height: 200px;
  }
}